<template>
  <div class="auth-page">
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold">
                <span class="primary--text">Strong Expert</span>
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <validation-observer
              ref="form"
              autocomplete="true"
              tag="form"
              @keyup.enter="auth()"
            >
              <validation-provider
                v-slot="{ errors }"
                name="E-mail"
                rules="required|max:100"
                vid="email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  outlined
                  dense
                  label="Email"
                  placeholder="john@example.com"
                  class="mb-3 no-shadow"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Senha"
                rules="required|max:64"
                vid="password"
              >
                <v-text-field
                  v-model="password"
                  outlined
                  dense
                  class="no-shadow"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors"
                  label="Senha"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                />
              </validation-provider>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <v-checkbox
                  v-model="rememberMe"
                  hide-details
                  class="me-3 mt-1"
                  @click="setRememberMe"
                >
                  <template v-slot:label>
                    <span class="dark:white--text">Lembrar-me</span>
                  </template>
                </v-checkbox>

                <a
                  href="/recovery-password"
                  class="mt-1"
                >
                  Esqueceu sua senha?
                </a>
              </div>

              <v-btn
                block
                color="primary"
                class="mt-6"
                @click="auth()"
              >
                Entrar
              </v-btn>

              <div class="d-flex align-center justify-center flex-wrap mt-6">
                <div
                  id="g_id_onload"
                  :data-client_id="googleClientId"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-callback="handleCredentialResponse"
                  data-auto_prompt="true"
                >
                </div>

                <div
                  class="g_id_signin"
                  data-type="standard"
                  data-shape="pill"
                  data-theme="outline"
                  data-text="sign_in_with"
                  data-size="large"
                  data-logo_alignment="left"
                >
                </div>
              </div>
            </validation-observer>
          </v-card-text>

          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2 dark:white--text">
              Não possui cadastro?
            </span>
            <router-link :to="{name:'signup'}">
              Crie a sua conta aqui
            </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div
      v-for="index in 6"
      :key="index"
      class="cube"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const rememberMe = ref(String(localStorage.getItem('remember-me')) === 'true')
    const isPasswordVisible = ref(false)
    const email = ref(localStorage.getItem('email'))
    const password = ref('')

    const googleClientId = '406454925828-c97a9gbt4kg9l0jfbv9d0mon6r16o33e.apps.googleusercontent.com'

    return {
      isPasswordVisible,
      rememberMe,
      email,
      password,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      googleClientId,
    }
  },

  created() {
    if (!this.rememberMe) {
      localStorage.removeItem('email')
    }
  },

  mounted() {
    this.loadGoogleScript()

    window.handleCredentialResponse = this.handleCredentialResponse
  },

  methods: {
    ...mapActions('auth', [
      'signin',
      'gAuthSignin',
    ]),

    setRememberMe() {
      localStorage.setItem('remember-me', this.rememberMe)
    },

    async handleCredentialResponse(response) {
      const token = response.credential
      const res = await this.gAuthSignin({ token })

      if (res.token) {
        this.$router.push('/report/service')

        this.$store.commit('app/setNotify', {
          visible: false,
          color: '',
          message: '',
          time: -1,
        })
      }
    },

    loadGoogleScript() {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    },

    async auth() {
      if (await this.$refs.form.validate()) {
        const res = await this.signin({
          email: this.email,
          password: this.$sha256(this.password),
        })

        if (res.token) {
          this.$router.push('/report/service')

          this.$store.commit('app/setNotify', {
            visible: false,
            color: '',
            message: '',
            time: -1,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
